import React, { useState, useEffect } from 'react';

import { Badge, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { logoutUser } from 'actions';
import { getInitials } from 'utils/commonUtils';
import { getBackgroundColorBasedOnCondition } from 'utils/commonUtils';
import ConfirmationAlert from 'components/ConfimationAlert/ConfirmationAlert';

const SidebarUserbox = (props) => {
  const { accessToken } = props;
  const { logoutUser, user, view } = props;
  const [officialEmail, setOfficialEmail] = useState();
  const [displayConfirmModal, setDisplayConfirmModel] = useState(false);
  const [confirmAlertMessage, setConfirmAlertMessage] = useState('');

  useEffect(() => {
    const reqEmail = user.emails?.find(
      (emailData) => emailData?.type?.toLowerCase() === 'Official'.toLowerCase()
    );
    setOfficialEmail(reqEmail?.email);
  }, [user]);
  const handleCloseConfirmModal = () => {
    setDisplayConfirmModel(false);
  };
  const logout = () => {
    setDisplayConfirmModel(true);
    setConfirmAlertMessage('Log out');
  };
  const saveData = () => {
    logoutUser(accessToken);
    window.location.href = 'login';
    setDisplayConfirmModel(false);
  };
  return (
    <>
      <div className="app-sidebar--userbox">
        <div className="avatar-icon-wrapper avatar-icon-md">
          <Badge
            variant="dot"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            badgeContent=" "
            overlap="circle"
            classes={{ badge: 'bg-success badge-circle' }}>
            <div className="avatar-icon rounded-circle">
              {user.profilePic ? (
                <img
                  className="img-fluid img-fit-container rounded-sm"
                  src={user.profilePic}
                  alt="..."
                />
              ) : (
                <div
                  className="initials-avatar"
                  style={{
                    backgroundColor:
                      user.username === 'admin'
                        ? getBackgroundColorBasedOnCondition(user.username)
                        : getBackgroundColorBasedOnCondition(user.legalName)
                  }}>
                  {user.username === 'admin'
                    ? getInitials(user.username)
                    : getInitials(user.legalName)}
                </div>
              )}
            </div>
          </Badge>
        </div>
        {view === 'Employee' ? (
          <div className="my-2 userbox-details">
            <span className="text-capitalize">{user.designationName}</span>
          </div>
        ) : (
          ''
        )}
        {view === 'Admin' ? (
          <div className="my-3 userbox-details">
            <span className="text-capitalize">{user.username}</span>
            <small className="d-block text-white-50">
              ({officialEmail ? officialEmail : 'Official Mail Id Not Added'})
            </small>
          </div>
        ) : (
          <div className=" userbox-details">
            <div className=" userbox-details">
              <span className="text-capitalize">
                {user.firstName +
                  ' ' +
                  (user.middleName === undefined ? '' : user.middleName) +
                  ' ' +
                  user.lastName}{' '}
              </span>
            </div>
            <div className="my-2 userbox-details">
              <span>
                {officialEmail ? officialEmail : 'Official Mail Id Not Added'}
              </span>
            </div>
          </div>
        )}
        {view === 'Admin' ? (
          <Button onClick={() => logout()} size="small" className="btn-userbox">
            Logout
          </Button>
        ) : (
          ''
        )}
      </div>
      <ConfirmationAlert
        showModal={displayConfirmModal}
        message={confirmAlertMessage}
        saveData={saveData}
        handleCloseConfirmModal={handleCloseConfirmModal}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.Auth.isAuthenticated,
  user: state.Auth.user,
  view: state.Auth.view,
  accessToken: state.Auth.accessToken
});
const mapDispatchToProps = (dispatch) => ({
  logoutUser: (accessToken) => dispatch(logoutUser(accessToken))
});
export default connect(mapStateToProps, mapDispatchToProps)(SidebarUserbox);
