import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';

import HeaderUserbox from '../../layout-components/HeaderUserbox';
import HeaderMenu from '../../layout-components/HeaderMenu';
import UserHeaderMenu from '../../layout-components/UserHeaderMenu';
import MyTeamHeaderMenu from 'layout-components/MyTeamHeaderMenu';
import MyLeavesHeaderMenu from 'layout-components/MyLeavesHeaderMenu';
import TeamLeavesHeaderMenu from 'layout-components/TeamLeavesHeaderMenu';
import MyCompensationHeaderMenu from 'layout-components/MyCompensationHeaderMenu';
import TeamSalaryHeaderMenu from 'layout-components/TeamSalaryHeaderMenu';
import TeamCompensationHeaderMenu from 'layout-components/TeamCompensationHeaderMenu';

const Header = (props) => {
  const {
    headerShadow,
    headerBgTransparent,
    sidebarToggleMobile,
    setSidebarToggleMobile,
    companyId,
    showHeader,
    headerType,
    view,
    isAdminViewEnabled
  } = props;
  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };
  return (
    <>
      <div
        className={clsx('app-header', {
          'app-header--shadow': headerShadow,
          'app-header--opacity-bg': headerBgTransparent
        })}>
        <div className="app-header--pane">
          <button
            className={clsx(
              'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
              { 'is-active': sidebarToggleMobile }
            )}
            onClick={toggleSidebarMobile}>
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
          {showHeader && view === 'Admin' ? (
            <HeaderMenu companyId={companyId} />
          ) : (
            ''
          )}
          {view === 'Employee' ? (
            <>
              {isAdminViewEnabled ? (
                <HeaderMenu companyId={companyId} />
              ) : (
                <>
                  {headerType.toLowerCase() === 'MyProfile'.toLowerCase() && (
                    <UserHeaderMenu />
                  )}
                  {headerType.toLowerCase() === 'MyTeam'.toLowerCase() && (
                    <MyTeamHeaderMenu />
                  )}
                  {headerType.toLowerCase() === 'MyLeaves'.toLowerCase() && (
                    <MyLeavesHeaderMenu />
                  )}
                  {headerType.toLowerCase() === 'Leaves'.toLowerCase() && (
                    <TeamLeavesHeaderMenu />
                  )}
                  {headerType.toLowerCase() ===
                    'MyCompensation'.toLowerCase() && (
                    <MyCompensationHeaderMenu />
                  )}
                  {headerType.toLowerCase() === 'TeamSalary'.toLowerCase() && (
                    <TeamSalaryHeaderMenu />
                  )}
                  {headerType.toLowerCase() ===
                    'TeamCompensation'.toLowerCase() && (
                    <TeamCompensationHeaderMenu />
                  )}
                </>
              )}
            </>
          ) : (
            ''
          )}
        </div>
        <div className="app-header--pane">
          <HeaderUserbox />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerBgTransparent: state.ThemeOptions.headerBgTransparent,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  view: state.Auth.view,
  isAdminViewEnabled: state.Auth.isAdminViewEnabled,
  isUserMapped: state.Auth.isUserMapped
});
const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
